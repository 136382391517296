import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WatchIcon from '../../../ressources/icons/watch2.png';
import StyledIcon from '../StyledIcon/StyledIcon';
import { HBox } from '../../../ressources/styles/styled-generic';
import IconBtn from '../../Buttons/IconBtn';

interface Props {
    count: number;
    clickAction?: () => void;
}

function WatchCount({ count, clickAction }: Props) {
  const { t } = useTranslation();
  return (
    <HBox>
      { count && <Typography>{count}</Typography> }
      { clickAction
        ? <IconBtn size="small" action={clickAction} icon={<StyledIcon source={WatchIcon} />} tooltip={t('watchManager.showUser')} />
        : <StyledIcon source={WatchIcon} />}
    </HBox>
  );
}

export default WatchCount;
