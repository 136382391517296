import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  Box,
  LinearProgress, styled,
} from '@mui/material';
import { theme } from '../../ressources/styles/theme';

interface Props {
    children: React.ReactNode[];
    headerValues: string[];
    loading: boolean;
}

const StyledTableCell = styled(TableCell)<{index: number}>`
    background-color: ${(props) => props.theme.palette.primary.main};
    color: ${theme.colors.white};
    border-top-left-radius: ${(index) => (index ? '0' : '10px')};
`;

export default function BasicTable({ children, headerValues, loading }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: '100%' }} stickyHeader>
        <TableHead>
          <TableRow>
            {
              headerValues.map((value, index) => (
                <StyledTableCell key={value} index={index}>{value}</StyledTableCell>
              ))
              }
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
      {loading && <Box width="100%"><LinearProgress /></Box> }
    </TableContainer>
  );
}
