import { ThemeProvider } from '@mui/material';
import React, { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeContext } from './context/theme/ThemeContext';
import AppRouter from './routes/AppRouter';
import LoadingProvider from './context/loading/LoadingContextProvider';
import SnackBarContextProvider from './context/snackBar/SnackBarContextProvider';
import UserProvider from './context/user/UserContextProvider';

function App() {
  const { theme } = useContext(ThemeContext);

  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <SnackBarContextProvider>
          <BrowserRouter>
            <UserProvider>
              <AppRouter />
            </UserProvider>
          </BrowserRouter>
        </SnackBarContextProvider>
      </LoadingProvider>
    </ThemeProvider>
  );
}

export default App;
