import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileSaver from 'file-saver';
import { LinearProgress } from '@mui/material';
import { HBox, VBox } from '../../ressources/styles/styled-generic';
import DialogActionsBox from '../Dialog/DialogActionsBox';
import SelectDate from '../Inputs/SelectDate';
import { api } from '../../api/api';

interface Props {
    handleDialog: () => void;
}

function DownloadCsv({ handleDialog }: Props) {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const downloadHistoryAsCSV = async () => {
    setLoading(true);
    const params = new URLSearchParams({
      ...(startDate ? { startDate } : {}),
      ...(endDate ? { endDate } : {}),
    });
    const result = await api.watches.unpairHistoryExport(params.toString());
    FileSaver.saveAs(result, 'unpairHistory.csv');
    setLoading(false);
    handleDialog();
  };

  return (
    <VBox gap="1rem" mt=".5rem">
      <HBox>
        <SelectDate setValue={setStartDate} label={t('watchManager.startDate')} />
        <SelectDate setValue={setEndDate} label={t('watchManager.endDate')} />
      </HBox>
      <DialogActionsBox
        actionRight={downloadHistoryAsCSV}
        actionLeft={handleDialog}
        actionRightLabel={t('watchManager.downloadAsCsv')}
        actionLeftLabel={t('general.cancel')}
        loading={loading}
      />
      {loading && <LinearProgress />}
    </VBox>
  );
}

export default DownloadCsv;
