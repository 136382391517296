import { OktaAuth } from '@okta/okta-auth-js';
import axios, { AxiosError } from 'axios';
import FileSaver from 'file-saver';
import config from '../config/config';
import { MultipleUsersResponseType, UserContextType, UserResponseType } from '../types/user';
import { WatchResults } from '../types/watch';
import endpoints from './endpoints';
import { UnpairWatchFormType } from '../types/forms/unpairWatch';
import { ApiResponseType } from '../types/apiResponse';
import { ProfileResponseType, ProfileType } from '../types/profiles';
import { AppUserResponseType } from '../types/appUser';
import { UnpairHistoryResponse } from '../types/unpairHistory';
import { oktaConfigType } from '../types/okta';

export const instance = axios.create({
  baseURL: `${config.api.url}/api`,
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
  maxRedirects: 0,
});

export const api = {
  init: {
    getContext: async (): Promise<UserContextType> => instance.post(endpoints.init.context),
    getConfiguration: async (): Promise<oktaConfigType> => instance.get(endpoints.init.configuration),
  },
  watches: {
    searchWatches: async (serialNumber: string, accountId?: string): Promise<WatchResults> => instance.post(endpoints.watches.path, { serialNumber, accountId }),
    searchUser: async (accountId?: string): Promise<UserResponseType> => instance.post(endpoints.users.path, { accountId }),
    searchUsers: async (searchPhrase: string): Promise<MultipleUsersResponseType> => instance.post(endpoints.users.path, { searchPhrase }),
    sendUnpairForm: async (unpairForm: UnpairWatchFormType): Promise<ApiResponseType> => instance.post(endpoints.watches.unpairForm, unpairForm),
    forceUnpair: async (assetName: string, serialNumber: string): Promise<ApiResponseType> => instance.post(endpoints.watches.forceUnpair, { assetName, serialNumber }),
    unpairHistory: async (size: number, offset: number): Promise<UnpairHistoryResponse> => instance.post(endpoints.watches.unpairHistory, { size, offset }),
    unpairHistoryExport: (query: string): Promise<Blob> => instance.get(endpoints.watches.downloadHistory + query, { responseType: 'blob' }),
  },
  appUsers: {
    getAllUsers: async (): Promise<AppUserResponseType> => instance.get(endpoints.appUsers.allUsers),
    getAllProfiles: async (): Promise<ProfileResponseType> => instance.get(endpoints.appUsers.allProfiles),
    updateUserProfile: async (mail: string, profile?: string): Promise<ApiResponseType> => instance.post(endpoints.appUsers.updateUserProfile, { mail, profile }),
    disableUser: async (userId: string): Promise<ApiResponseType> => instance.post(endpoints.appUsers.disableUser, { userId }),
    enableUser: async (userId: string): Promise<ApiResponseType> => instance.post(endpoints.appUsers.enableUser, { userId }),
    deleteUser: async (userId: string): Promise<ApiResponseType> => instance.post(endpoints.appUsers.deleteUser, { userId }),
    createUser: async (mail: string, profile: string): Promise<ApiResponseType> => instance.post(endpoints.appUsers.createUser, { mail, profile }),
  },
  profiles: {
    deleteProfile: async (profileId: string): Promise<ApiResponseType> => instance.post(endpoints.profiles.deleteProfile, { profileId }),
    updateProfile: async (newProfile: ProfileType): Promise<ApiResponseType> => instance.post(endpoints.profiles.updateProfile, { newProfile }),
    createProfile: async (newProfile: ProfileType): Promise<ApiResponseType> => instance.post(endpoints.profiles.createProfile, { newProfile }),
  },
};

instance.interceptors.response.use(
  async (response) => response.data,
  async (error: AxiosError) => {
    if (axios.isCancel(error)) {
      return;
    }
    throw error;
  },
);
