import React from 'react';
import { Typography } from '@mui/material';
import SearchOffOutlinedIcon from '@mui/icons-material/SearchOffOutlined';
import { useTranslation } from 'react-i18next';
import { HBox } from '../../../ressources/styles/styled-generic';

function NoResult() {
  const { t } = useTranslation();

  return (
    <HBox mt="1rem">
      <SearchOffOutlinedIcon color="secondary" />
      <Typography color="secondary">{t('general.noResult')}</Typography>
    </HBox>
  );
}

export default NoResult;
