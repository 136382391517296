export default {
  init: {
    context: 'context',
    configuration: 'configuration',
  },
  watches: {
    path: '/salesforce/watches',
    unpairForm: '/azureV2/watch/unpairform',
    forceUnpair: 'azureV2/watch/forceunpair',
    unpairHistory: 'azure/watch/unpairhistory',
    unpairHistoryExport: 'azure/watch/unpairhistory/export',
    downloadHistory: 'azure/watch/unpairhistory/export?',
  },
  users: {
    path: '/salesforce/users',
  },
  appUsers: {
    allUsers: '/getAllUsers',
    allProfiles: 'getAllProfiles',
    updateUserProfile: 'updateUserProfile',
    disableUser: 'disableUser',
    enableUser: 'activateUser',
    deleteUser: 'deleteUser',
    createUser: 'createUser',
  },
  profiles: {
    deleteProfile: 'deleteProfile',
    updateProfile: 'updateProfile',
    createProfile: 'createProfile',
  },
};
