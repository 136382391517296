import { Theme } from '@mui/material';
import React, {
  ReactNode, useMemo, useState,
} from 'react';
import { MuiDefaultTheme } from '../../ressources/styles/theme';
import { ThemeContext } from './ThemeContext';

interface Props {
  children: ReactNode | ReactNode[];
}

export default function ThemeColorProvider({ children }: Props) {
  const [theme, setTheme] = useState<Theme>(MuiDefaultTheme);

  const setThemeInfos = (theme: Theme):void => {
    setTheme(theme);
  };

  const value = useMemo(() => ({
    theme, setThemeInfos,
  }), [theme]);

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
}
