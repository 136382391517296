import React, { useContext, useState } from 'react';
import { LinearProgress, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WatchType } from '../../types/watch';
import config from '../../config/config';
import { HBox, VBox } from '../../ressources/styles/styled-generic';
import { UserContext } from '../../context/user/UserContext';
import { UserType } from '../../types/user';
import { UnpairWatchFormType } from '../../types/forms/unpairWatch';
import { api } from '../../api/api';
import DialogActionsBox from '../Dialog/DialogActionsBox';
import { SnackBarContext } from '../../context/snackBar/SnackBarContext';
import { handleApiCalls } from '../Assets/utils';

interface Props {
    watch: WatchType,
    oldClient: UserType;
    handleDialog: () => void;
    resetSearch: (value: string, item: string) => Promise<void>
}

function UnpairForm({
  watch, oldClient, handleDialog, resetSearch,
}: Props) {
  const { userInfo } = useContext(UserContext);
  const { setSnackInfos } = useContext(SnackBarContext);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<UnpairWatchFormType>({
    oldClientEmail: oldClient.email || '',
    oldClientId: oldClient.id || '',
    oldClientName: `${oldClient.firstname} ${oldClient.lastname}` || '',
    salesforcetoken: watch.name,
    serialNumber: watch.serialNumber || '',
    unpairClientName: '',
    unpairReason: '',
    unpairWatchCaseSerial: '',
    unpairWatchContactis: '',
  });
  const { t } = useTranslation();
  const { inputNames } = config.watchManager;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    await handleApiCalls({ promise: api.watches.sendUnpairForm(formValues), setLoading });
    await handleApiCalls({
      promise: api.watches.forceUnpair(formValues.salesforcetoken, formValues.serialNumber),
      setLoading,
      setSnackInfos,
      successMsg: t('watchManager.snackMessages.unpair.success'),
      errorMsg: t('watchManager.snackMessages.unpair.error'),
    });
    resetSearch(watch.serialNumber, config.watchManager.values.search.watch.value);
    handleDialog();
  };

  return (
    <VBox>
      <VBox gap="1rem" width="25rem" padding=".5rem">
        <TextField disabled={!!formValues.serialNumber || loading} name={inputNames.serialNumber} label={t('watchManager.serial')} value={formValues.serialNumber} />
        <TextField disabled={!!watch.iotKey || loading} label={t('watchManager.iotKey')} value={watch.iotKey} />
        <TextField disabled={!!formValues.oldClientName || loading} name={inputNames.oldClientEmail} label={t('watchManager.client')} value={formValues.oldClientName} onChange={handleInputChange} />
        <TextField disabled label={t('watchManager.requester')} value={userInfo && userInfo.login} />
        <TextField disabled={loading} name={inputNames.unpairWatchContactis} label={t('watchManager.Contactis')} value={formValues.unpairWatchContactis} onChange={handleInputChange} />
        <TextField disabled={loading} name={inputNames.unpairWatchCaseSerial} label={t('watchManager.case')} value={formValues.unpairWatchCaseSerial} onChange={handleInputChange} />
        <TextField disabled={loading} required name={inputNames.unpairReason} label={t('watchManager.reason')} value={formValues.unpairReason} multiline rows={2} onChange={handleInputChange} />
      </VBox>
      { loading && <LinearProgress /> }
      <DialogActionsBox
        actionRight={handleSubmit}
        actionLeft={handleDialog}
        actionRightLabel={t('watchManager.unpair')}
        actionLeftLabel={t('general.cancel')}
        loading={loading}
        critical
      />
    </VBox>
  );
}

export default UnpairForm;
