import { Box, IconButton, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../context/theme/ThemeContext';
import {
  Content,
  ExpandeIconContainer,
  LeftContent, ListItemContainer, MiddleContent, RightContent, SubContent, UpContent,
} from './listItem.styled';
import ExpandArrow from '../../Assets/ExpandArrow/ExpandArrow';
import { HBox } from '../../../ressources/styles/styled-generic';
import Separator from '../../Assets/Separator/Separator';
import ExpandedItem from '../ExpandedItems/ExpandedItem';

interface Props {
  icon?: React.ReactNode;
  title?: string;
  middleText?: string;
  rightContent?: React.ReactNode;
  subInfos?: string[];
  expandable?: boolean;
  children?: React.ReactNode;
  highlight?: boolean;
  disableSeparator?: boolean;
  autoExpand?: boolean;
}

function ListItem({
  icon, title, middleText, rightContent, subInfos, expandable, children, highlight, disableSeparator, autoExpand,
}: Props) {
  const { theme } = useContext(ThemeContext);
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (!expandable) setExpanded(false);
  }, [expandable]);

  useEffect(() => {
    setExpanded(!!autoExpand);
  }, [autoExpand]);

  return (
    <ListItemContainer borderColor={theme.palette.primary.main} $highlight={highlight}>
      <HBox>
        {icon}
        <Content>
          <UpContent>
            <LeftContent>
              <Typography>{title}</Typography>
            </LeftContent>
            <MiddleContent>
              <Typography>{middleText}</Typography>
            </MiddleContent>
            <RightContent>
              {rightContent}
            </RightContent>
          </UpContent>
          <SubContent>
            {
              subInfos && subInfos.map((info, index) => (
                <Typography mr="3rem" key={index} color="secondary">{info}</Typography>
              ))
            }
          </SubContent>
        </Content>
        <ExpandeIconContainer>
          {
            expandable
            && (
            <IconButton onClick={handleClick}>
              <ExpandArrow isExpanded={expanded} />
            </IconButton>
            )
          }
        </ExpandeIconContainer>
      </HBox>
      {
        expanded
        && (
          <>
            {!disableSeparator && <Separator />}
            <ExpandedItem>
              {children}
            </ExpandedItem>
          </>
        )
      }
    </ListItemContainer>
  );
}

export default ListItem;
