import React from 'react';
import styled from 'styled-components';
import { VBox } from '../../ressources/styles/styled-generic';
import { theme } from '../../ressources/styles/theme';

const StyledPage = styled(VBox)`
  align-items: center;
  width: 100%;
  height: calc(100% - ${theme.layout.headerHeight});
  gap: 1rem;
  box-sizing: border-box;
`;

interface Props {
    children: React.ReactNode | React.ReactNode[];
}

function PageContainer({ children }: Props) {
  return (
    <StyledPage>{children}</StyledPage>
  );
}

export default PageContainer;
