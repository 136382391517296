import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogActionsBox from '../Dialog/DialogActionsBox';
import { VBox } from '../../ressources/styles/styled-generic';

interface Props {
    message: string;
    confirmAction: () => void;
    cancelAction: () => void;

}

function Confirm({ message, confirmAction, cancelAction }: Props) {
  const { t } = useTranslation();
  return (
    <VBox gap="1rem" alignItems="center">
      <Typography>{message}</Typography>
      <DialogActionsBox
        actionRight={confirmAction}
        actionLeft={cancelAction}
        actionRightLabel={t('general.confirm')}
        actionLeftLabel={t('general.cancel')}
        critical
      />
    </VBox>
  );
}

export default Confirm;
