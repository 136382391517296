import React, { useContext, useEffect } from 'react';
import {
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import User from './UserInfos/UserInfos';
import NavList from './NavList/NavList';
import { UserContext } from '../../context/user/UserContext';
import { VBox } from '../../ressources/styles/styled-generic';
import { theme } from '../../ressources/styles/theme';

const MenuContainer = styled(VBox)`
  justify-content: space-between;
  width: ${theme.layout.navWidth};
  box-sizing: border-box;
  padding: .5rem 0;
  height: 100%;
  box-shadow: 0 0 5px ${theme.colors.veryLightGrey};
  background-color: ${theme.colors.veryLightGrey};
`;

function Menu() {
  const { userInfo } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo?.applications?.watchv2.manager) navigate('/watch-manager/search');
  }, [userInfo]);

  return (
    <MenuContainer>
      <User />
      <NavList />
      <Typography component="h1" variant="body2" textAlign="center" paddingTop="2.5rem" fontWeight="bold">
        {t('general.appTitle')}
        {' '}
        (V
        {userInfo?.appVersion}
        ) [
        {userInfo && userInfo.environmentName}
        ]
      </Typography>
    </MenuContainer>
  );
}

export default Menu;
