import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { UnpairHistoryItem } from '../../../types/unpairHistory';
import DialogActionsBox from '../../../components/Dialog/DialogActionsBox';
import { HBox, VBox } from '../../../ressources/styles/styled-generic';

interface Props {
    item: UnpairHistoryItem;
    handleDialog: () => void;
}

function DetailedUnpair({ item, handleDialog }: Props) {
  const { t } = useTranslation();
  return (
    <VBox>
      <VBox mt="1rem" mb="1rem">
        <HBox>
          <Typography fontWeight="bold">{t('watchManager.oldIotKey')}</Typography>
          <Typography>{item.iotKey || 'N/A'}</Typography>
        </HBox>
        <HBox>
          <Typography fontWeight="bold">{t('watchManager.contactisTicket')}</Typography>
          <Typography>{item.Contactis || 'N/A'}</Typography>
        </HBox>
        {' '}
        <HBox>
          <Typography fontWeight="bold">{t('watchManager.caseSerial')}</Typography>
          <Typography>{item.unpairWatchCaseSerial || 'N/A'}</Typography>
        </HBox>
        {' '}
        <HBox>
          <Typography fontWeight="bold">{t('watchManager.requesterClient')}</Typography>
          <Typography>{item.unpairClientName || 'N/A'}</Typography>
        </HBox>
        {' '}
        <HBox>
          <Typography fontWeight="bold">{t('watchManager.oldClientName')}</Typography>
          <Typography>{item.oldClientName || 'N/A'}</Typography>
        </HBox>
        {' '}
        <HBox>
          <Typography fontWeight="bold">{t('watchManager.oldClientEmail')}</Typography>
          <Typography>{item.oldClientEmail || 'N/A'}</Typography>
        </HBox>
        {' '}
        <HBox>
          <Typography fontWeight="bold">{t('watchManager.oldClientId')}</Typography>
          <Typography>{item.oldClientId || 'N/A'}</Typography>
        </HBox>
      </VBox>
      <DialogActionsBox
        actionRight={handleDialog}
        actionRightLabel={t('general.close')}
      />
    </VBox>
  );
}

export default DetailedUnpair;
