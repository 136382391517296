import React from 'react';
import ViewContainer from '../../components/Containers/ViewContainer';
import ComingSoon from '../../components/Containers/ComingSoon';

function AzureLogsPage() {
  return (
    <ViewContainer>
      <ComingSoon />
    </ViewContainer>
  );
}

export default AzureLogsPage;
