import { Box, Theme } from '@mui/material';
import styled from 'styled-components';
import { HBox } from '../../../ressources/styles/styled-generic';
import { theme } from '../../../ressources/styles/theme';

interface ImageProps {
  isSelected?: boolean;
  theme?: Theme;
}

export const Icon = styled.img<ImageProps>`
  width: 2rem;
  padding: 1rem 0;
  filter: opacity(30%) drop-shadow(0 0 0 ${(props) => (props.isSelected ? `${props.theme.palette.primary.main}` : '')});
`;

export const ItemContainer = styled(HBox)`
  gap: 1.5rem; 
  height: fit-content;
  &:hover {
    background-color: ${theme.colors.lightGrey};
    cursor: pointer;
  }
`;

export const Thumb = styled(Box)`
  width: .5rem;
  height: 4rem;
  border-radius: 0 10px 10px 0;
`;
