import React from 'react';
import { CreateStyled } from '@emotion/styled';
import { ApiResponseType } from '../../types/apiResponse';
import { SnackValue } from '../../context/snackBar/SnackBarContext';
import { theme } from '../../ressources/styles/theme';

interface handleApiCallsArgs {
  promise: Promise<ApiResponseType>;
  setLoading?: (value: boolean) => void | React.Dispatch<React.SetStateAction<boolean>>;
  setSnackInfos?: (snackValue: SnackValue) => void;
  successMsg?: string;
  errorMsg?: string;
}

export const capitalizeFirstLetter = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

export const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
};

export const handleApiCalls = async ({
  promise, setLoading, setSnackInfos, successMsg, errorMsg,
}: handleApiCallsArgs) => {
  try {
    if (setLoading) setLoading(true);
    await promise;
    if (setLoading) setLoading(false);
    if (setSnackInfos && successMsg) {
      setSnackInfos({
        message: successMsg, open: true, severity: 'success',
      });
    }
  } catch (e) {
    if (setSnackInfos && errorMsg) {
      setSnackInfos({
        message: errorMsg, open: true, severity: 'error',
      });
    }
    console.error(`${errorMsg} . Error: ${e}`);
  }
};
