import React, { useState } from 'react';
import { LinearProgress, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { VBox } from '../../ressources/styles/styled-generic';
import DialogActionsBox from '../Dialog/DialogActionsBox';
import { ProfileType } from '../../types/profiles';
import ExpandedProfile from '../List/ExpandedItems/ExpandedProfile/ExpandedProfile';

interface Props {
    closeDialog: () => void;
    handleCreateProfile: (profile: ProfileType, setLoading: (value: boolean) => void) => Promise<void>;
}

const newProfile = {
  _id: '',
  api: {
    azure: {
      promise12: {
        logs: false,
        clearmessage: false,
        getmessage: false,
        register: false,
        pair: false,
        unpair: false,
        unpairhistory: false,
        weather: false,
        flight: false,
        air: false,
      },
      horizonv2: {
        track: false,
        pairmodule: false,
        unpairmodule: false,
        pairluggage: false,
        unpairluggage: false,
        pairmoduleluggage: false,
        unpairmoduleluggage: false,
        unpairhistory: false,
        logs: false,
      },
    },
    azureV2: {
      register: false,
      pair: false,
      unpair: false,
    },
    salesforce: {
      owner: false,
      users: false,
      watches: false,
      luggages: false,
      modules: false,
    },
    heroku: {
      position: false,
      poi: false,
      poicount: false,
    },
  },
  applications: {
    admin: false,
    watch: {
      dashboard: false,
      manager: false,
      azurelogs: false,
      qrcodegenerator: false,
    },
    luggage: {
      dashboard: false,
      manager: false,
      azurelogs: false,
      qrcodegenerator: false,
      map: false,
    },
    watchv2: {
      dashboard: false,
      manager: false,
    },
  },
};

function CreateProfile({ closeDialog, handleCreateProfile }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<ProfileType>(newProfile);
  const { t } = useTranslation();

  return (
    <VBox width="60rem" justifyContent="end">
      <VBox mt=".5rem" gap="2rem" height="25rem">
        <TextField required error={!profile._id} sx={{ margin: '0 1.5rem' }} variant="outlined" label={t('adminPage.profileName')} placeholder={t('adminPage.profileName')} value={profile._id} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setProfile({ ...profile, _id: event.target.value })} />
        <ExpandedProfile profile={profile} setProfile={setProfile} loading={loading} updateProfile={() => {}} enableSelectAll />
      </VBox>
      { loading && <LinearProgress /> }
      <DialogActionsBox
        actionRight={() => handleCreateProfile(profile, setLoading)}
        actionLeft={closeDialog}
        actionRightLabel={t('general.create')}
        actionLeftLabel={t('general.cancel')}
        loading={loading}
        disable={!profile._id}
      />
    </VBox>
  );
}

export default CreateProfile;
