import React, { useContext, useEffect, useState } from 'react';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useOutletContext } from 'react-router-dom';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../../components/Containers/PageContainer';
import Search from '../../../components/Inputs/Search';
import { api } from '../../../api/api';
import { ProfileType } from '../../../types/profiles';
import { capitalizeFirstLetter, handleApiCalls } from '../../../components/Assets/utils';
import ListItem from '../../../components/List/ListItem/ListItem';
import { AppUserType } from '../../../types/appUser';
import List from '../../../components/List/List';
import ToggleSwitch from '../../../components/Inputs/ToggleSwitch';
import { HBox } from '../../../ressources/styles/styled-generic';
import config from '../../../config/config';
import SelectInput from '../../../components/Inputs/SelectInput';
import { SnackBarContext } from '../../../context/snackBar/SnackBarContext';
import DialogWrapper from '../../../components/Dialog/DialogWrapper';
import IconBtn from '../../../components/Buttons/IconBtn';
import Confirm from '../../../components/Forms/Confirm';
import CreateUser from '../../../components/Forms/CreateUser';
import { LoadingContext } from '../../../context/loading/LoadingContext';

function UserManagerPage() {
  const [users, setUsers] = useState<AppUserType[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<AppUserType[]>([]);
  const [toggleProfiles, setToggleProfiles] = useState<{value: string}[]>([]);
  const [alignment, setAlignment] = useState<string>('Admin');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const { profiles } = useOutletContext<{profiles: ProfileType[]}>();
  const { setSnackInfos } = useContext(SnackBarContext);
  const { setLoadingInfo } = useContext(LoadingContext);
  const { t } = useTranslation();
  const { getAllProfilesValue } = config.adminPage;
  const {
    enableUser, disableUser, updateUserProfile, deleteUser,
  } = api.appUsers;

  const getUsers = async () => {
    const result = await api.appUsers.getAllUsers();
    setUsers(result.body);
  };

  const updateUsrProfile = async (value: string, email?: string) => {
    if (email) {
      await handleApiCalls({
        promise: updateUserProfile(email, value),
        setSnackInfos,
        successMsg: t('adminPage.snackMessages.changeUserProfile.success'),
        errorMsg: t('adminPage.snackMessages.changeUserProfile.error'),
      });
      getUsers();
    }
  };

  const confirmDeleteUser = async () => {
    await handleApiCalls({
      promise: deleteUser(selectedUserId),
      setLoading: setLoadingInfo,
      setSnackInfos,
      successMsg: t('adminPage.snackMessages.supressUser.success'),
      errorMsg: t('adminPage.snackMessages.supressUser.error'),
    });
    getUsers();
    setOpenConfirm(false);
  };

  const handleActive = async (userId: string, value: boolean) => {
    if (value) {
      await handleApiCalls({
        promise: enableUser(userId),
        setSnackInfos,
        successMsg: t('adminPage.snackMessages.activateUser.success'),
        errorMsg: t('adminPage.snackMessages.activateUser.error'),
      });
    } else {
      await handleApiCalls({
        promise: disableUser(userId),
        setSnackInfos,
        successMsg: t('adminPage.snackMessages.unactivateUser.success'),
        errorMsg: t('adminPage.snackMessages.unactivateUser.error'),
      });
    }
    getUsers();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, userId: string) => {
    handleActive(userId, event.target.checked);
  };

  const handleLiveSearch = (value: string) => {
    setFilteredUsers(users.filter((user) => user._id.includes(value)));
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (selectedUserId) setOpenConfirm(true);
  }, [selectedUserId]);

  useEffect(() => {
    setToggleProfiles([{ value: 'All profiles' }].concat(profiles.map((profile) => (
      { value: capitalizeFirstLetter(profile._id) }
    ))));
    setAlignment(getAllProfilesValue);
  }, [profiles]);

  useEffect(() => {
    setFilteredUsers(alignment === getAllProfilesValue ? users : users.filter((user) => user.profileId === alignment.toLowerCase()));
  }, [alignment, users]);

  return (
    <PageContainer>
      <Search
        toggleButtons
        toggleItems={toggleProfiles}
        setAlignment={setAlignment}
        alignment={alignment}
        liveSearchAction={handleLiveSearch}
        placeholder={t('adminPage.searchUser')}
        action={() => setOpenDialog(true)}
        actionLabel={t('adminPage.createUser')}
        actionIcon={<AddBoxOutlinedIcon />}
      />
      <List>
        {
          filteredUsers.map((user) => (
            <ListItem
              key={user._id}
              highlight
              title={user._id}
              icon={
                <ManageAccountsOutlinedIcon color="secondary" fontSize="large" />
                }
              rightContent={(
                <HBox width="20rem" justifyContent="space-between" mr="-2rem">
                  <SelectInput disableFormControl targetId={user._id} initValue={capitalizeFirstLetter(user.profileId)} items={toggleProfiles.map((profile) => profile.value)} changeAction={updateUsrProfile} />
                  <ToggleSwitch value={!user.disabled} label={user.disabled ? t('adminPage.inactive') : t('adminPage.active')} changeAction={(event) => handleChange(event, user._id)} />
                  <IconBtn action={() => setSelectedUserId(user._id)} tooltip={t('adminPage.deleteUser')} icon={<DeleteOutlineOutlinedIcon />} />
                </HBox>
                )}
            />
          ))
        }
      </List>
      <DialogWrapper
        openDialog={openConfirm}
      >
        <Confirm message={t('adminPage.deleteUserWarning')} confirmAction={confirmDeleteUser} cancelAction={() => setOpenConfirm(false)} />
      </DialogWrapper>
      <DialogWrapper
        openDialog={openDialog}
        headerIcon={<ManageAccountsOutlinedIcon color="secondary" fontSize="large" />}
        title={t('adminPage.userCreation')}
      >
        <CreateUser items={toggleProfiles.map((profile) => profile.value)} closeDialog={() => setOpenDialog(false)} refresh={getUsers} />
      </DialogWrapper>
    </PageContainer>
  );
}

export default UserManagerPage;
