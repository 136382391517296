import { Switch, Typography } from '@mui/material';
import React from 'react';
import { HBox } from '../../ressources/styles/styled-generic';

interface Props {
    value: boolean;
    changeAction: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    reverse?: boolean;
    align?: 'right' | 'left'
}

export default function ToggleSwitch({
  value, changeAction, label, reverse, align,
}: Props) {
  return (
    <HBox flexDirection={reverse ? 'row-reverse' : 'row'} justifyContent="start" width="fit-content" ml={align === 'right' ? 'auto' : ''} mr={align === 'left' ? 'auto' : ''}>
      <Switch checked={value} onChange={changeAction} />
      <Typography textAlign="start" color="secondary">{label}</Typography>
    </HBox>

  );
}
