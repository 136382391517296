import React, {
  ReactNode, useMemo, useState,
} from 'react';
import { UserContext } from './UserContext';
import { UserContextType } from '../../types/user';

interface Props {
  children: ReactNode | ReactNode[];
}

export default function UserProvider({ children }: Props) {
  const [userInfo, setUserInfo] = useState<UserContextType | null>(null);

  const setUserInfos = (infos: UserContextType | null):void => {
    if (infos) setUserInfo(infos);
  };

  const value = useMemo(() => ({
    userInfo, setUserInfos,
  }), [userInfo]);

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}
