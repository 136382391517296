import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import NavTabs from '../../components/NavTabs/NavTabs';
import ViewContainer from '../../components/Containers/ViewContainer';
import { homeChildren } from '../../routes/LoggedRoutes';
import { api } from '../../api/api';
import { ProfileType } from '../../types/profiles';

function AdminPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [profiles, setProfiles] = useState<ProfileType[]>([]);

  const getProfiles = async () => {
    const result = await api.appUsers.getAllProfiles();
    setProfiles(result.body);
  };

  useEffect(() => {
    navigate('user');
    getProfiles();
  }, []);

  const appInfos = homeChildren().find((application) => application.path && location.pathname.includes(application.path));
  return (
    <ViewContainer>
      <NavTabs sections={appInfos && appInfos.children} />
      <Outlet context={{ profiles, getProfiles }} />
    </ViewContainer>
  );
}

export default AdminPage;
