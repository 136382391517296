import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

interface Props {
  action: () => void;
  tooltip: string;
  color?: 'default' | 'inherit' | 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';
  icon: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
}

function IconBtn({
  action, tooltip, color, icon, size,
}: Props) {
  return (
    <Tooltip title={tooltip}>
      <IconButton size={size || 'medium'} color={color || 'default'} onClick={action}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}

export default IconBtn;
