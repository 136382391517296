import React, { useContext } from 'react';
import {
  Avatar, CircularProgress, ListItemIcon, Menu, MenuItem, Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useSpring, animated } from 'react-spring';
import { useTranslation } from 'react-i18next';
import { useOktaAuth } from '@okta/okta-react';
import {
  UserInfosContainer,
  UserNavbarSection,
} from './userInfos.styled';
import { ThemeContext } from '../../../context/theme/ThemeContext';
import { UserContext } from '../../../context/user/UserContext';

function UserInfos() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { userInfo } = useContext(UserContext);
  const { theme } = useContext(ThemeContext);
  const { oktaAuth } = useOktaAuth();
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const toggleWrapperAnimatedStyle = useSpring({ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' });
  const userName = userInfo && userInfo.login.split('@')[0];
  const userInitial = userInfo && userInfo.login.charAt(0).toUpperCase();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    oktaAuth.signOut();
  };

  return (
    <UserInfosContainer>
      <>
        <Tooltip title={t('general.accountSettings')}>
          <UserNavbarSection
            onClick={handleClick}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
              {userInfo ? userInitial : <CircularProgress color="info" />}
            </Avatar>
            <Typography fontWeight="bolder" margin="0" padding="0">{userName && (userInitial + userName.slice(1))}</Typography>
            <animated.div style={toggleWrapperAnimatedStyle}>
              <KeyboardArrowDown />
            </animated.div>
          </UserNavbarSection>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleLogOut}>
            <ListItemIcon>
              <Logout fontSize="small" color="primary" />
            </ListItemIcon>
            <Typography color="primary">{t('general.logOut')}</Typography>

          </MenuItem>
        </Menu>
      </>
    </UserInfosContainer>
  );
}

export default UserInfos;
