import styled from 'styled-components';
import Grid2 from '@mui/material/Unstable_Grid2';
import { theme } from '../../../ressources/styles/theme';
import { HBox, VBox } from '../../../ressources/styles/styled-generic';

export const UserNavbarSection = styled(HBox)`
  justify-content: center;
  margin-right: auto;
  gap: 1rem;
  border-radius: 40px;
  padding: .5rem;
  cursor: pointer;
  :hover {
    background-color: ${theme.colors.lightGrey};
  }
  `;

export const UserInfosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: .5em;
`;

export const userIdsContainer = styled(VBox)`
  gap: 0;
`;
