import React, { useState } from 'react';
import {
  Tab, Tabs,
} from '@mui/material';
import {
  useNavigate,
} from 'react-router-dom';
import { CustomRouteObject } from '../../types/customRoute';

interface Props {
  sections?: () => CustomRouteObject[];
}

function NavTabs({ sections }: Props) {
  const [tabsValue, setTabsValue] = useState(0);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };

  return (
    <Tabs
      value={tabsValue}
      onChange={handleChange}
      sx={{
        padding: '1rem 0 0 2rem',
      }}
    >
      {
        sections && sections().map((section, index: number) => (
          <Tab key={index} label={section.name} value={index} onClick={() => navigate(section.path ? section.path : '')} />
        ))
      }
    </Tabs>
  );
}

export default NavTabs;
