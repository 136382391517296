import React, { useContext, useState } from 'react';
import { Button } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useOutletContext } from 'react-router-dom';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { useTranslation } from 'react-i18next';
import { VBox } from '../../../ressources/styles/styled-generic';
import List from '../../../components/List/List';
import ListItem from '../../../components/List/ListItem/ListItem';
import { ProfileType } from '../../../types/profiles';
import PageContainer from '../../../components/Containers/PageContainer';
import { capitalizeFirstLetter, handleApiCalls } from '../../../components/Assets/utils';
import IconBtn from '../../../components/Buttons/IconBtn';
import ExpandedProfile from '../../../components/List/ExpandedItems/ExpandedProfile/ExpandedProfile';
import { api } from '../../../api/api';
import { SnackBarContext } from '../../../context/snackBar/SnackBarContext';
import { LoadingContext } from '../../../context/loading/LoadingContext';
import DialogWrapper from '../../../components/Dialog/DialogWrapper';
import CreateProfile from '../../../components/Forms/CreateProfile';

function ProfileManagerPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { profiles, getProfiles } = useOutletContext<{
    profiles: ProfileType[],
    getProfiles:() => Promise<void>
  }>();
  const { t } = useTranslation();
  const { setSnackInfos } = useContext(SnackBarContext);
  const { setLoadingInfo } = useContext(LoadingContext);
  const { createProfile, updateProfile, deleteProfile } = api.profiles;

  const handleCreateProfile = async (profile: ProfileType, setLoading: (value: boolean) => void) => {
    await handleApiCalls({
      promise: createProfile(profile),
      setLoading,
      setSnackInfos,
      successMsg: t('adminPage.snackMessages.addProfile.success'),
      errorMsg: t('adminPage.snackMessages.addProfile.error'),
    });
    getProfiles();
    setOpenDialog(false);
  };

  const handleUpdateProfile = async (profile: ProfileType) => {
    await handleApiCalls({
      promise: updateProfile(profile),
      setSnackInfos,
      successMsg: t('adminPage.snackMessages.modifyProfile.success'),
      errorMsg: t('adminPage.snackMessages.modifyProfile.error'),
      setLoading,
    });
    getProfiles();
  };

  const handleDeleteProfile = async (profileId: string) => {
    await handleApiCalls({
      promise: deleteProfile(profileId),
      setLoading: setLoadingInfo,
      setSnackInfos,
      successMsg: t('adminPage.snackMessages.eraseProfile.success'),
      errorMsg: t('adminPage.snackMessages.eraseProfile.error'),
    });
    getProfiles();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <PageContainer>
      <VBox width="80%" height="100%" alignItems="end">
        <Button sx={{ mr: '1rem' }} variant="contained" startIcon={<AddBoxOutlinedIcon />} onClick={() => setOpenDialog(true)}>{t('adminPage.createProfile')}</Button>
        <List fitcontainer>
          {
             profiles.map((profile, index) => (
               <ListItem
                 key={`${profile}_${index}`}
                 highlight
                 expandable
                 title={capitalizeFirstLetter(profile._id)}
                 icon={<BadgeOutlinedIcon fontSize="large" color="secondary" />}
                 rightContent={
                   <IconBtn action={() => handleDeleteProfile(profile._id)} tooltip={t('adminPage.deleteProfile')} icon={<DeleteOutlineOutlinedIcon />} />
                  }
               >
                 <ExpandedProfile profile={profile} updateProfile={handleUpdateProfile} loading={loading} />
               </ListItem>
             ))
          }
        </List>
      </VBox>
      <DialogWrapper
        openDialog={openDialog}
        headerIcon={<BadgeOutlinedIcon color="secondary" fontSize="large" />}
        title={t('adminPage.profileCreation')}
      >
        <CreateProfile handleCreateProfile={handleCreateProfile} closeDialog={handleCloseDialog} />
      </DialogWrapper>
    </PageContainer>
  );
}

export default ProfileManagerPage;
