import React from 'react';
import { Button, DialogActions } from '@mui/material';
import { DefaultTFuncReturn } from 'i18next';

interface Props {
    actionRightLabel?: DefaultTFuncReturn;
    critical?: boolean;
    actionLeftLabel?: DefaultTFuncReturn;
    actionRight: () => void;
    actionLeft?: () => void;
    loading?: boolean;
    disable?: boolean;
}

function DialogActionsBox({
  actionRightLabel, critical, actionLeftLabel, actionRight, actionLeft, loading, disable,
}: Props) {
  return (
    <DialogActions>
      <Button color="primary" disabled={loading} onClick={actionLeft}>{actionLeftLabel}</Button>
      <Button
        variant="contained"
        disabled={loading || disable}
        color={critical ? 'error' : 'primary'}
        onClick={actionRight}
      >
        {actionRightLabel}
      </Button>
    </DialogActions>
  );
}

export default DialogActionsBox;
