import React, { useEffect, useState } from 'react';
import {
  FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';

interface Props {
    targetId?: string;
    items: string[];
    variant?: 'standard' | 'outlined' | 'filled' | undefined;
    label?: string;
    disableFormControl?: boolean;
    initValue?: string;
    required?: boolean;
    changeAction?: (value: string, id?: string) => void;
}

function SelectInput({
  targetId, items, variant, label, disableFormControl, initValue, required, changeAction,
}: Props) {
  const [value, setValue] = useState<string>('');

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (changeAction) changeAction(newValue.toLocaleLowerCase(), targetId);
  };

  useEffect(() => {
    if (initValue) setValue(initValue);
  }, [initValue]);

  if (disableFormControl) {
    return (
      <Select required={required} variant={variant || 'standard'} disableUnderline onChange={handleChange} value={value} defaultValue="">
        {items.map((item, index) => (
          <MenuItem key={index} value={item}>{item}</MenuItem>
        ))}
      </Select>
    );
  }

  return (
    <FormControl>
      <InputLabel required={required}>{label}</InputLabel>
      <Select required={required} variant={variant || 'standard'} disableUnderline onChange={handleChange} value={value} defaultValue="" label={label}>
        {items.map((item, index) => (
          <MenuItem key={index} value={item}>{item}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectInput;
