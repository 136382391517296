import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import NavTabs from '../../components/NavTabs/NavTabs';
import { homeChildren } from '../../routes/LoggedRoutes';
import ViewContainer from '../../components/Containers/ViewContainer';

function WatchManagerPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const urlInfos = location.pathname.split('/');
  const appInfos = homeChildren().find((application) => application.path && urlInfos[1] === application.path);

  useEffect(() => {
    navigate('search');
  }, []);

  return (
    <ViewContainer>
      <NavTabs sections={appInfos && appInfos.children} />
      <Outlet />
    </ViewContainer>
  );
}

export default WatchManagerPage;
