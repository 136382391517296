import React, { useState } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { homeChildren, loggedRoutes } from '../../../routes/LoggedRoutes';
import NavListItem from './NavListItem';

const NavListContainer = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: .5rem;
  gap: 0;
`;

function NavList() {
  const location = useLocation();

  return (
    <NavListContainer>
      <Box component="nav" sx={{ listStyle: 'none' }}>
        {
          homeChildren().filter((child) => child.path).map((route) => (
            <li key={route.name}>
              {(route.path && route.authorized)
              && (
              <Box component={Link} to={`/${route.path}`} sx={{ textDecoration: 'none', color: 'black' }}>
                <NavListItem
                  route={route}
                  isSelected={location.pathname.includes(route.path)}
                />
              </Box>
              )}
            </li>
          ))
        }
      </Box>
    </NavListContainer>
  );
}

export default NavList;
