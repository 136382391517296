import { ToggleButton, styled } from '@mui/material';
import { transientOptions } from '../Assets/utils';
import { theme } from '../../ressources/styles/theme';

const StyledToggleButton = styled(ToggleButton, transientOptions)<{$isselected: boolean}>`
  background-color: ${(props) => (props.$isselected ? props.theme.palette.primary.main : '')};
  color: ${(props) => (props.$isselected ? theme.colors.white : props.theme.palette.primary.main)};
  border: ${(props) => `1px solid ${props.theme.palette.primary.main}`};
  &:hover {
    background-color: ${(props) => (props.$isselected ? props.theme.palette.primary.main : theme.colors.white)};
    ${(props) => props.$isselected && 'cursor: auto;'}
  }
`;

export default StyledToggleButton;
