import React from 'react';
import { Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import { VBox } from '../../ressources/styles/styled-generic';

function ComingSoon() {
  return (
    <VBox width="100%" mt="15rem" alignItems="center">
      <ConstructionIcon fontSize="large" color="primary" />
      <Typography color="secondary" variant="h4">Coming soon</Typography>
    </VBox>
  );
}

export default ComingSoon;
