import React, { useContext, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import config from '../../../config/config';
import { api } from '../../../api/api';
import { WatchType } from '../../../types/watch';
import { LoadingContext } from '../../../context/loading/LoadingContext';
import Search from '../../../components/Inputs/Search';
import WatchesResults from './Results/WatchResults';
import UserResults from './Results/UserResults';
import { UserType } from '../../../types/user';
import PageContainer from '../../../components/Containers/PageContainer';
import { searchUserWatches } from '../WatchFunctions';
import NoResult from '../../../components/Assets/NoResult/NoResult';

function SearchPage() {
  const { search } = config.watchManager.values;
  const [watches, setWatches] = useState<WatchType[]>([]);
  const [foundWatches, setFoundWatches] = useState<WatchType[] | null>(null);
  const [foundUsers, setFoundUsers] = useState<UserType[]>([]);
  const [usersWithWatches, setUsersWithWathes] = useState<UserType[]>([]);
  const [alignment, setAlignment] = useState<string>(search.watch.value);
  const [noResult, setNoResult] = useState<boolean>(false);
  const [autoExpand, setAutoExpand] = useState<boolean>(false);
  const { setLoadingInfo } = useContext(LoadingContext);
  const { t } = useTranslation();

  const handleSearch = async (value: string, alignment: string) => {
    setLoadingInfo(true);
    setAutoExpand(false);
    setNoResult(false);
    try {
      if (alignment === search.watch.value) {
        const results = await api.watches.searchWatches(value);
        setFoundWatches(results.body);
      } else {
        const results = await api.watches.searchUsers(value);
        if (!results.body.length) setNoResult(true);
        setFoundUsers(results.body);
      }
      setLoadingInfo(false);
    } catch (e) {
      console.error(`Failed to log user. Error: ${e}`);
    }
  };

  const getUserWithId = async (id: string) => {
    const result = await api.watches.searchUser(id);
    return result.body;
  };

  const handleShowUser = async (accountId: string) => {
    setAlignment(search.user.value);
    setFoundUsers([await getUserWithId(accountId)]);
  };

  const getUsersWatches = async () => {
    const newUsers = await Promise.all(foundUsers.map(async (user) => {
      const watches = await searchUserWatches(user.accountId || '');
      return { ...user, watches };
    }));
    setUsersWithWathes(newUsers);
  };

  const handleAction = (value?: string, alignment?: string) => {
    if (value && alignment) handleSearch(value, alignment);
  };

  const handleClear = () => {
    setFoundUsers([]);
    setWatches([]);
  };

  useEffect(() => {
    if (foundUsers.length === 1) setAutoExpand(true);
    setUsersWithWathes([]);
    getUsersWatches();
  }, [foundUsers]);

  useEffect(() => {
    if (foundWatches) setWatches((previousWatches) => Array.from([...previousWatches, ...foundWatches].reduce((m, o) => m.set(o.name, o), new Map()).values()));
    setNoResult(!foundWatches);
  }, [foundWatches]);

  return (
    <PageContainer>
      <Search
        toggleButtons
        toggleItems={[search.watch, search.user]}
        alignment={alignment}
        setAlignment={setAlignment}
        action={handleAction}
        clearAction={handleClear}
        actionLabel={t('general.search')}
        actionIcon={<SearchIcon />}
        placeholder={alignment === search.watch.value ? search.watch.placeholder : search.user.placeholder}
      />
      {
        alignment === search.user.value
          ? <UserResults users={usersWithWatches.length > 0 ? usersWithWatches : foundUsers} getUsersWatches={getUsersWatches} autoExpand={autoExpand} />
          : <WatchesResults watches={watches} handleSearch={handleSearch} onShowUser={handleShowUser} />
      }
      {noResult && <NoResult />}
    </PageContainer>
  );
}

export default SearchPage;
