import React, {
  ReactNode, useMemo, useState,
} from 'react';
import { LoadingContext } from './LoadingContext';

interface Props {
children: ReactNode;
}

export default function LoadingProvider({ children }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const setLoadingInfo = (value: boolean):void => {
    setLoading(value);
  };

  const value = useMemo(() => ({
    loading, setLoadingInfo,
  }), [loading]);

  return (
    <LoadingContext.Provider value={value}>
      {children}
    </LoadingContext.Provider>
  );
}
