import React, { ReactNode, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { VBox } from '../../ressources/styles/styled-generic';
import { transientOptions } from '../Assets/utils';

const ListContainer = styled(Box, transientOptions)<{$fitcontainer: boolean, $enableScroll: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: ${(props) => (props.$fitcontainer ? '100%' : '79.5%')};
  max-height: 100%;
  overflow-y: ${(props) => (props.$enableScroll ? 'auto' : 'hidden')};
  padding: 1rem;
`;

interface Props {
    children: ReactNode | ReactNode[];
    fitcontainer?: boolean;
    disableScroll?: boolean;
}

function List({
  children, fitcontainer, disableScroll,
}: Props) {
  const [isFitcontainer, setIsFitcontainer] = useState<boolean>(false);
  const [enableScroll, setEnableScroll] = useState<boolean>(true);

  useEffect(() => {
    if (fitcontainer) setIsFitcontainer(fitcontainer);
  }, [fitcontainer]);

  useEffect(() => {
    if (disableScroll) setEnableScroll(!disableScroll);
  }, [disableScroll]);

  return (
    <ListContainer $fitcontainer={isFitcontainer} $enableScroll={enableScroll}>
      {children}
    </ListContainer>
  );
}

export default List;
