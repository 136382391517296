import React, { useContext, useEffect, useState } from 'react';
import {
  Navigate, useLocation,
} from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { UserContext } from '../context/user/UserContext';
import { api } from '../api/api';
import { SnackBarContext } from '../context/snackBar/SnackBarContext';
import { ApiResponseType } from '../types/apiResponse';

  interface Props {
    children: JSX.Element;
  }

function ProtectedRoute({ children }: Props) {
  const [render, setRender] = useState(children);
  const location = useLocation();
  const { oktaAuth } = useOktaAuth();
  const { setUserInfos } = useContext(UserContext);
  const { setSnackInfos } = useContext(SnackBarContext);
  const { t } = useTranslation();

  const getUserInfo = async (): Promise<void> => {
    try {
      const userInfos = await api.init.getContext();
      setUserInfos({ ...userInfos });
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        const errorData = axiosError.response.data as ApiResponseType;
        if (axiosError.response.status === 403 && errorData.reason) setSnackInfos({ message: errorData.reason, open: true, severity: 'error' });
      }
      setRender(<Navigate to="/signin" replace state={{ from: location }} />);
    }
  };

  const customAuthHandler = async () => {
    const previousAuthState = await oktaAuth?.isAuthenticated();

    if (!previousAuthState) {
      setRender(<Navigate to="/signin" replace state={{ from: location }} />);
    }
    getUserInfo();
  };

  useEffect(() => {
    customAuthHandler();
  }, [oktaAuth]);

  return render;
}

export default ProtectedRoute;
