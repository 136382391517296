import {
  LinearProgress, TextField,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VBox } from '../../ressources/styles/styled-generic';
import SelectInput from '../Inputs/SelectInput';
import DialogActionsBox from '../Dialog/DialogActionsBox';
import { handleApiCalls } from '../Assets/utils';
import { api } from '../../api/api';
import { SnackBarContext } from '../../context/snackBar/SnackBarContext';
import ToggleSwitch from '../Inputs/ToggleSwitch';
import config from '../../config/config';

interface Props {
    items: string[];
    closeDialog: () => void;
    refresh: () => void;
}

function CreateUser({
  items, closeDialog, refresh,
}: Props) {
  const [username, setUsername] = useState<string>('');
  const [mail, setMail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [profile, setProfile] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isPartner, setIsPartner] = useState<boolean>(false);
  const { setSnackInfos } = useContext(SnackBarContext);
  const { t } = useTranslation();

  const handleChangeProfile = (value: typeof profile) => {
    setProfile(value);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChangeUsername = (value: string) => {
    setUsername(value);
    if (isPartner) {
      setMail(`p-${value + config.adminPage.partnerUserMail}`);
    } else {
      setMail(value + config.adminPage.internalUserMail);
    }
  };

  const handleSubmit = async () => {
    await handleApiCalls({
      promise: api.appUsers.createUser(mail, profile),
      setLoading,
      setSnackInfos,
      successMsg: t('adminPage.snackMessages.addUser.success'),
      errorMsg: t('adminPage.snackMessages.addUser.error'),
    });
    refresh();
    closeDialog();
  };

  useEffect(() => {
    const isValid = validateEmail(mail);
    if (mail) setEmailError(!isValid);
  }, [mail]);

  useEffect(() => {
    setUsername('');
    setMail('');
  }, [isPartner]);

  return (
    <VBox width="20rem" mt=".5rem" sx={{ gap: '1rem' }}>
      <ToggleSwitch
        changeAction={() => setIsPartner(!isPartner)}
        value={isPartner}
        label={t('adminPage.partnerLabel')}
        align="right"
        reverse
      />
      <SelectInput
        required
        label={t('adminPage.profile')}
        items={items}
        variant="outlined"
        changeAction={handleChangeProfile}
      />
      <TextField
        label={t('adminPage.username')}
        value={username}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeUsername(event.target.value)}
        required
        sx={{ mt: '.5rem' }}
      >
        {username}
      </TextField>
      <TextField
        label={t('adminPage.mail')}
        value={mail}
        required
        disabled
        type="email"
        error={emailError}
        helperText={emailError && t('adminPage.emailError')}
        sx={{ mt: '.5rem' }}
      >
        {mail}
      </TextField>
      { loading && <LinearProgress /> }
      <DialogActionsBox
        actionRight={handleSubmit}
        actionLeft={closeDialog}
        actionRightLabel={t('general.create')}
        actionLeftLabel={t('general.cancel')}
        loading={loading}
        disable={!username || !mail || !profile || emailError}
      />
    </VBox>
  );
}

export default CreateUser;
