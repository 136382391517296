import styled from 'styled-components';
import LoginIcon from '@mui/icons-material/Login';
import { HBox, VBox } from '../../ressources/styles/styled-generic';

export const LoginPageContainer = styled(HBox)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: hidden;
`;

export const LoginContainer = styled(VBox)`
  width:100%;
  height:100%;
  margin-bottom: 10%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
