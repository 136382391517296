import { api } from '../../api/api';

export const searchWatchUser = async (value: string) => {
  const result = await api.watches.searchUser(value);
  return (result.body);
};

export const searchUserWatches = async (value: string) => {
  const result = await api.watches.searchWatches('', value);
  return (result.body);
};
