import React, { useContext } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import {
  Alert, Button, Snackbar, Typography,
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import { useTranslation } from 'react-i18next';
import { LoginContainer, LoginPageContainer } from './LoginPage.styled';
import { SnackBarContext } from '../../context/snackBar/SnackBarContext';

function LoginPage() {
  const { oktaAuth } = useOktaAuth();
  const { snack } = useContext(SnackBarContext);
  const { t } = useTranslation();

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  return (
    <LoginPageContainer>
      <LoginContainer>
        <Typography variant="h4">{t('login.title')}</Typography>
        <Button variant="contained" startIcon={<LoginIcon />} onClick={login} type="button">{t('login.buttonLabel').toUpperCase()}</Button>
        <Typography variant="h6">{t('login.subTitle')}</Typography>
      </LoginContainer>
      <Snackbar open={snack.open} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity={snack.severity} variant="filled" sx={{ width: '100%' }}>
          {snack.message}
        </Alert>
      </Snackbar>
    </LoginPageContainer>
  );
}

export default LoginPage;
