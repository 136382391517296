import {
  Route, Routes,
} from 'react-router-dom';
import { loggedRoutes } from './LoggedRoutes';
import { CustomRouteObject } from '../types/customRoute';

function LoggedRouteProvider() {
  const mapRoute = (elem: CustomRouteObject) => {
    if (elem.children) {
      return (
        <Route key={elem.name} path={elem.path} element={elem.element}>
          {
              elem.children()
                .filter((route) => route.authorized)
                .map((child) => mapRoute(child))
            }
        </Route>
      );
    }
    return <Route key={elem.name} path={elem.path} element={elem.element} />;
  };
  return (
    <Routes>
      {
        loggedRoutes()
          .filter((route) => route.authorized)
          .map((route) => mapRoute(route))
      }
    </Routes>
  );
}

export default LoggedRouteProvider;
