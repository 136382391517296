import React, { useEffect, useState } from 'react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
  LinearProgress,
} from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { useTranslation } from 'react-i18next';
import { UserType } from '../../../../types/user';
import { HBox } from '../../../../ressources/styles/styled-generic';
import WatchIcon from '../../../../ressources/icons/watch2.png';
import { WatchType } from '../../../../types/watch';
import DialogWrapper from '../../../Dialog/DialogWrapper';
import UnpairForm from '../../../Forms/UnpairForm';
import StyledIcon from '../../../Assets/StyledIcon/StyledIcon';
import WatchCount from '../../../Assets/WatchCount/WatchCount';
import ListItem from '../../ListItem/ListItem';
import IconBtn from '../../../Buttons/IconBtn';

interface Props {
  userInfo: string;
  watch: WatchType;
  resetSearch: (value: string, item: string) => Promise<void>;
  searchWatchUser: (value: string) => Promise<UserType>;
  searchUserWatches: (value: string) => Promise<WatchType[]>;
  countClickAction?: () => void;
}

function ExpandedWatch({
  userInfo, watch, resetSearch, searchWatchUser, searchUserWatches, countClickAction,
}: Props) {
  const [user, setUser] = useState<UserType>({});
  const [watches, setWatches] = useState<WatchType[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { t } = useTranslation();

  const getUser = async () => {
    setUser(await searchWatchUser(userInfo));
  };

  const getWatches = async () => {
    setWatches(await searchUserWatches(userInfo));
  };

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  useEffect(() => {
    getUser();
  }, [userInfo]);

  useEffect(() => {
    getWatches();
  }, [user]);

  if (user.accountId) {
    return (
      <>
        <ListItem
          icon={
            <PersonOutlineIcon color="secondary" fontSize="large" />
          }
          title={`${user.firstname?.charAt(0).toUpperCase()}${user.firstname?.slice(1)} ${user.lastname?.toUpperCase()}`}
          middleText={user.email}
          rightContent={(
            <HBox>
              <WatchCount count={watches.length} clickAction={countClickAction} />
              <IconBtn tooltip={t('watchManager.tooltips.unpair')} color="error" icon={<LinkOffIcon />} action={handleDialog} />
            </HBox>
          )}
        />
        <DialogWrapper
          openDialog={openDialog}
          headerIcon={<StyledIcon source={WatchIcon} />}
          title={t('watchManager.unpairRequestFormTitle')}
        >
          <UnpairForm watch={watch} oldClient={user} handleDialog={handleDialog} resetSearch={resetSearch} />
        </DialogWrapper>
      </>
    );
  }

  return <LinearProgress sx={{ marginTop: '-0.5rem' }} />;
}

export default ExpandedWatch;
