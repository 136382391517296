import React from 'react';
import { Icon } from '@mui/material';
import styled from 'styled-components';

const StyledIconContainer = styled.img<{size: string}>`
  padding: .25rem;
  width: ${(props) => props.size};
`;

interface Props {
    source: string;
    size?: string;
}

function StyledIcon({ source, size }: Props) {
  return (
    <StyledIconContainer src={source} alt="" size={size || '1.5rem'} />
  );
}

export default StyledIcon;
