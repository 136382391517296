import { BorderColor } from '@mui/icons-material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { HBox, VBox } from '../../../ressources/styles/styled-generic';
import { theme } from '../../../ressources/styles/theme';
import { transientOptions } from '../../Assets/utils';

interface ContainerProps {
    borderColor?: string;
    $highlight?: boolean;
}

export const ListItemContainer = styled(VBox, transientOptions)<ContainerProps>`
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
    border-left: ${(props) => (props.$highlight ? `.5rem solid ${props.borderColor}` : 'none')};
    border-radius: 10px;
    box-shadow: ${(props) => (props.$highlight ? `.5px .5px 1.5px ${theme.colors.grey}` : 'none')};
    padding: ${(props) => (props.$highlight ? '.5rem 2rem' : '0 0 0 1rem')};
`;
export const Content = styled(VBox)`
    padding: 0 1rem;
    width: 100%;
`;

export const UpContent = styled(HBox)`
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const LeftContent = styled(HBox)`
    gap: 3rem;
    width: 33.33%;
`;

export const MiddleContent = styled(HBox)`
    align-items: start;
    margin-right: auto;
`;

export const RightContent = styled(HBox)`
    gap: 2rem;
`;

export const SubContent = styled(HBox)`
    justify-content: start;
`;

export const ExpandeIconContainer = styled(Box)`
    width: 1rem;
`;
