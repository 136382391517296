import React from 'react';
import { Box, styled } from '@mui/material';
import { theme } from '../../../ressources/styles/theme';

const StyledSeparator = styled(Box)`
    border-top: 1px solid ${theme.colors.lightGrey};
    margin: .5rem 0 1rem -2rem;
    width: 100%;
`;

function Separator() {
  return (
    <StyledSeparator />
  );
}

export default Separator;
