import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './english';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: english,
      },
    },
  });

export default i18n;
