import React from 'react';
import styled from 'styled-components';
import { VBox } from '../../ressources/styles/styled-generic';
import { theme } from '../../ressources/styles/theme';

interface Props {
    children: React.ReactNode;
}

const StyledViewContainer = styled(VBox)`
    align-items: flex-start;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: ${theme.layout.navWidth};
    gap: 2rem;
  `;

function ViewContainer({ children }: Props) {
  return (
    <StyledViewContainer>{children}</StyledViewContainer>
  );
}

export default ViewContainer;
