import React, { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation } from 'react-i18next';
import {
  Pagination, PaginationItem, TableCell, TableRow,
} from '@mui/material';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import WatchIcon from '../../../ressources/icons/watch2.png';
import { HBox } from '../../../ressources/styles/styled-generic';
import PageContainer from '../../../components/Containers/PageContainer';
import IconBtn from '../../../components/Buttons/IconBtn';
import List from '../../../components/List/List';
import config from '../../../config/config';
import { api } from '../../../api/api';
import BasicTable from '../../../components/Table/BasicTable';
import { UnpairHistoryItem, UnpairHistoryTableItem } from '../../../types/unpairHistory';
import DialogWrapper from '../../../components/Dialog/DialogWrapper';
import StyledIcon from '../../../components/Assets/StyledIcon/StyledIcon';
import DetailedUnpair from './DetailedUnpair';
import DownloadCsv from '../../../components/Forms/DownloadCsv';

function HistoryPage() {
  const [history, setHistory] = useState<UnpairHistoryItem[]>([]);
  const [historyTable, setHistoryTable] = useState<UnpairHistoryTableItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [end, setEnd] = useState<boolean>(false);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [openDetailsDialog, setOpenDetailsDialog] = useState<boolean>(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<UnpairHistoryItem | null>(null);
  const { t } = useTranslation();

  const getHistory = async (offset: number) => {
    setLoading(true);
    const results = await api.watches.unpairHistory(20, offset);
    if (results.unpairhistory.length < 20) setEnd(true);
    setHistory(history.concat(results.unpairhistory));
    setLoading(false);
  };

  const handleDetailsDialog = (index: number) => {
    setSelectedRow(history[index]);
    setOpenDetailsDialog(true);
  };

  const handleRefresh = () => {
    setHistory([]);
    getHistory(0);
    setCount(1);
    setPage(1);
    setEnd(false);
  };

  useEffect(() => {
    getHistory(0);
  }, []);

  const nextAction = () => {
    if (count === page) {
      setCount(count + 1);
      setHistoryTable([]);
      getHistory((page + 1) * 20);
    }
    setPage(page + 1);
  };

  useEffect(() => {
    setStartIndex((page - 1) * 20);
  }, [page]);

  useEffect(() => {
    if (history.length) {
      setHistoryTable(
        history.map((item) => {
          const {
            date, serialNumber, user, unpairReason,
          } = item;
          return {
            date, serialNumber, user, unpairReason,
          };
        }),
      );
    }
  }, [history]);

  return (
    <PageContainer>
      <List>
        <HBox width="100%" justifyContent="end">
          <IconBtn icon={<RefreshIcon />} action={handleRefresh} tooltip={t('watchManager.tooltips.refresh')} />
          <IconBtn icon={<FileDownloadIcon />} action={() => setOpenDownloadDialog(true)} tooltip={t('watchManager.tooltips.downloadCsv')} />
        </HBox>
        <BasicTable loading={loading} headerValues={config.watchManager.values.unpairHistoryTableHeader}>
          {
            historyTable.map((item, index) => (
              (index >= startIndex && index <= startIndex + 19)
              && (
              <TableRow
                hover
                key={item.date}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {item.date.replace('&nbsp;', ' ').replace(/[TZ]/g, ' ').split('.')[0]}
                </TableCell>
                <TableCell>{item.serialNumber}</TableCell>
                <TableCell>{item.user}</TableCell>
                <TableCell>{item.unpairReason}</TableCell>
                <TableCell>
                  <IconBtn icon={<AddCircleOutlineIcon fontSize="small" />} tooltip={t('watchManager.tooltips.showMore')} action={() => handleDetailsDialog(index)} size="small" color="primary" />
                </TableCell>
              </TableRow>
              )
            ))
          }
        </BasicTable>
      </List>
      {
        historyTable.length > 0
        && (
        <HBox gap="0" mb="0.5rem">
          <PaginationItem disabled={page <= 1} component={NavigateBefore} onClick={() => setPage(page - 1)} />
          <Pagination
            color="primary"
            hideNextButton
            hidePrevButton
            count={count}
            page={page}
            onChange={(event: React.ChangeEvent<unknown>, page: number) => setPage(page)}
          />
          <PaginationItem disabled={end} component={NavigateNext} onClick={nextAction} />
        </HBox>
        )
      }
      <DialogWrapper
        openDialog={openDetailsDialog}
        headerIcon={<StyledIcon source={WatchIcon} />}
        title={t('watchManager.unpairHistoryDetailed')}
      >
        { selectedRow && <DetailedUnpair item={selectedRow} handleDialog={() => setOpenDetailsDialog(false)} />}
      </DialogWrapper>
      <DialogWrapper
        openDialog={openDownloadDialog}
        headerIcon={<StyledIcon source={WatchIcon} />}
        title={t('watchManager.downloadCsv')}
      >
        <DownloadCsv handleDialog={() => setOpenDownloadDialog(false)} />
      </DialogWrapper>
    </PageContainer>
  );
}

export default HistoryPage;
