import React from 'react';
import { LoginCallback } from '@okta/okta-react';
import LoginPage from '../views/Login/LoginPage';
import ProtectedRoute from './ProtectedRoute';
import UserProvider from '../context/user/UserContextProvider';
import LoggedRouteProvider from './LoggedRouteProvider';

const routes: any[] = [
  {
    path: '/login/callback',
    element: (
      <LoginCallback
        errorComponent={() => <LoginPage />}
      />
    ),
  },
  {
    path: '/signin',
    element: <LoginPage />,
  },
  {
    path: '/*',
    element: <UserProvider><ProtectedRoute><LoggedRouteProvider /></ProtectedRoute></UserProvider>,
  },
];

export default routes;
