import React, { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { useTranslation } from 'react-i18next';
import { WatchType } from '../../../../types/watch';
import { UserType } from '../../../../types/user';
import List from '../../List';
import ListItem from '../../ListItem/ListItem';
import StyledIcon from '../../../Assets/StyledIcon/StyledIcon';
import WatchIcon from '../../../../ressources/icons/watch2.png';
import DialogWrapper from '../../../Dialog/DialogWrapper';
import UnpairForm from '../../../Forms/UnpairForm';
import NoResult from '../../../Assets/NoResult/NoResult';
import IconBtn from '../../../Buttons/IconBtn';
import { VBox } from '../../../../ressources/styles/styled-generic';

interface Props {
    user: UserType;
    resetAction: () => Promise<void>;
}

function ExpandedUser({ user, resetAction }: Props) {
  const [watches, setWatches] = useState<WatchType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedWatch, setSelectedWatch] = useState<WatchType>();
  const { t } = useTranslation();

  const handleDialog = (watch?: WatchType) => {
    if (watch)setSelectedWatch(watch);
    setOpenDialog(!openDialog);
  };

  useEffect(() => {
    if (user.watches) setWatches(user.watches);
  }, [user]);

  if (loading) {
    return <LinearProgress sx={{ marginTop: '-0.5rem' }} />;
  }

  return (
    <VBox alignItems="start" width="100%" height="100%">
      { !watches.length && <NoResult /> }
      <List fitcontainer>
        {
          watches.map((watch) => (
            <ListItem
              key={watch.id}
              title={t('watchManager.serial') + watch.serialNumber}
              subInfos={[(t('watchManager.sku') + watch.sku), (watch.iotKey ? t('watchManager.iotKey') + watch.iotKey : ''), (watch.casingSN ? t('watchManager.watchCase') + watch.casingSN : '')]}
              icon={
                <StyledIcon source={WatchIcon} size="2.5rem" />
                }
              rightContent={
                <IconBtn tooltip={t('watchManager.tooltips.unpair')} color="error" icon={<LinkOffIcon fontSize="small" />} size="small" action={() => handleDialog(watch)} />
                }
            />
          ))
        }
      </List>
      <DialogWrapper
        openDialog={openDialog}
        headerIcon={<StyledIcon source={WatchIcon} />}
        title={t('watchManager.unpairRequestFormTitle')}
      >
        {selectedWatch && <UnpairForm watch={selectedWatch} oldClient={user} handleDialog={handleDialog} resetSearch={resetAction} />}
      </DialogWrapper>
    </VBox>
  );
}

export default ExpandedUser;
