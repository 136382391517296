import React, { useContext, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../../../context/theme/ThemeContext';
import { CustomRouteObject } from '../../../types/customRoute';
import { Icon, ItemContainer, Thumb } from './NavListItem.styled';

interface Props {
  route: CustomRouteObject;
  isSelected: boolean;
}

function NavListItem({
  route, isSelected,
}: Props) {
  const { theme, setThemeInfos } = useContext(ThemeContext);
  const location = useLocation();
  const primary = theme.palette.primary.main;

  useEffect(() => {
    if (isSelected && route.theme) setThemeInfos(route.theme);
  }, [location]);

  return (
    <ItemContainer>
      <Thumb sx={{ backgroundColor: `${isSelected && primary}` }} />
      <Icon
        src={route.icon}
        alt={`${route.name} icon`}
        isSelected={isSelected}
        theme={route.theme}
      />
      <Typography variant="subtitle1" marginRight="1rem" color={isSelected ? primary : ''} fontWeight={isSelected ? 'bold' : ''}>{route.name}</Typography>
    </ItemContainer>
  );
}

export default NavListItem;
