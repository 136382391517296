export default {
  general: {
    appTitle: 'LV Control Tower',
    confirm: 'Confirm',
    cancel: 'Cancel',
    signOut: 'Sign out',
    logOut: 'Sign out',
    search: 'Search',
    clear: 'Clear',
    enter: 'Enter',
    create: 'Create',
    startDate: 'Start date',
    endDate: 'End date',
    close: 'Close',
    accountSettings: 'Account settings',
    enableAll: 'Enable all',
    disableAll: 'Disable all',
    noResult: 'No result',
  },
  login: {
    title: 'Welcome to Control Tower',
    buttonLabel: 'Sign in with SSO',
    subTitle: 'Identify with your Okta Account',
  },
  adminPage: {
    mail: 'Mail',
    username: 'Username',
    profile: 'Profile',
    emailError: 'Must be a valide email',
    active: 'Active',
    inactive: 'Inactive',
    searchUser: 'Search user',
    createUser: 'Create user',
    deleteUser: 'Delete user',
    partnerLabel: 'Partner user',
    createProfile: 'Create profile',
    deleteProfile: 'Delete profile',
    apiTitle: 'Api',
    applicationsTitle: 'Applications',
    profileName: 'Profile name',
    allProfiles: 'All profiles',
    userCreation: 'User creation',
    profileCreation: 'Profile creation',
    deleteUserWarning: 'You are about to definitively delete this user',
    snackMessages: {
      changeUserProfile: {
        success: 'User profile updated successfully',
        error: 'Failed to update user profile',
      },
      activateUser: {
        success: 'User enabled successfully',
        error: 'Failed to enable user',
      },
      unactivateUser: {
        success: 'User disabled successfully',
        error: 'Failed to disable user',
      },
      supressUser: {
        success: 'User deleted successfully',
        error: 'Failed to delete user',
      },
      addUser: {
        success: 'User created successfully',
        error: 'Failed to create user',
      },
      addProfile: {
        success: 'Profile  created successfully',
        error: 'Failed to create profile',
      },
      modifyProfile: {
        success: 'Profile updated successfully',
        error: 'Failed to update profile',
      },
      eraseProfile: {
        success: 'Profile deleted successfully',
        error: 'Failed to delete profile',
      },
    },

  },
  watchManager: {
    sku: 'Sku: ',
    iotKey: 'IoT Key: ',
    serial: 'Serial Number: ',
    watchCase: 'Watch case: ',
    ready: 'Ready',
    unpair: 'Unpair',
    unpairRequestFormTitle: 'Unpair Request',
    requester: 'Requester',
    Contactis: 'Contactis Ticket',
    case: 'Watch Case',
    client: 'Client Name',
    reason: 'Reason',
    unpairHistoryDetailed: 'Unpair detailed informations',
    oldIotKey: 'Old IoT Key: ',
    contactisTicket: 'Contactis Ticket: ',
    caseSerial: 'Case serial number: ',
    requesterClient: 'Requester client name: ',
    oldClientName: 'Old client name: ',
    oldClientEmail: 'Old client email: ',
    oldClientId: 'Old client Id: ',
    downloadCsv: 'Download CSV',
    downloadAsCsv: 'Download as CSV',
    startDate: 'Start date',
    endDate: 'End date',
    showUser: 'Display watches',
    snackMessages: {
      unpair: {
        success: 'Watch unpaired successfully',
        error: 'Failed to unpair watch',
      },
    },
    tooltips: {
      unpair: 'Unpair the watch',
      refresh: 'Refresh table',
      downloadCsv: 'Download as CSV',
      showMore: 'Show more informations',
    },
  },
};
