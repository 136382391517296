import React from 'react';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import List from '../../../../components/List/List';
import ListItem from '../../../../components/List/ListItem/ListItem';
import ExpandedWatch from '../../../../components/List/ExpandedItems/ExpandedWatch/ExpandedWatch';
import { WatchType } from '../../../../types/watch';
import config from '../../../../config/config';
import WatchIcon from '../../../../ressources/icons/watch2.png';
import StyledIcon from '../../../../components/Assets/StyledIcon/StyledIcon';
import { searchUserWatches, searchWatchUser } from '../../WatchFunctions';

interface Props {
    watches: WatchType[];
    handleSearch: (value: string, item: string) => Promise<void>;
    onShowUser?: (accountId: string) => void;
}

function WatchResults({ watches, handleSearch, onShowUser }: Props) {
  const { t } = useTranslation();

  return (
    <List>
      {
        watches.map((watch) => (
          <ListItem
            key={watch.serialNumber}
            expandable={watch.status !== t('watchManager.ready')}
            highlight
            title={t('watchManager.serial') + watch.serialNumber}
            rightContent={
              <Chip size="small" variant="outlined" color={watch.status === config.watchManager.values.watchReady ? 'success' : 'warning'} label={watch.status} />
            }
            subInfos={[(t('watchManager.sku') + watch.sku), (watch.iotKey ? t('watchManager.iotKey') + watch.iotKey : ''), (watch.casingSN ? t('watchManager.watchCase') + watch.casingSN : '')]}
            icon={(
              <StyledIcon
                source={WatchIcon}
                size="2.5rem"
              />
            )}
          >
            <ExpandedWatch
              userInfo={watch.accountId}
              watch={watch}
              resetSearch={handleSearch}
              searchWatchUser={searchWatchUser}
              searchUserWatches={searchUserWatches}
              countClickAction={onShowUser ? () => onShowUser(watch.accountId) : () => {}}
            />
          </ListItem>
        ))
        }
    </List>
  );
}

export default WatchResults;
