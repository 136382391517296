import React, { useEffect, useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment';

interface Props {
    label: string;
    setValue: React.Dispatch<React.SetStateAction<string | null>>
}

function SelectDate({ label, setValue }: Props) {
  const [dateValue, setDateValue] = useState<Moment | null>(null);

  useEffect(() => {
    if (dateValue) setValue(dateValue.format('YYYY-MM-DD'));
  }, [dateValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker value={dateValue} onChange={(newValue) => setDateValue(newValue)} label={label} format="DD-MM-YYYY" disableFuture />
      </DemoContainer>
    </LocalizationProvider>
  );
}

export default SelectDate;
