import { ToggleButtonGroup, ToggleButton, styled } from '@mui/material';
import React from 'react';
import { HBox } from '../../ressources/styles/styled-generic';
import { theme } from '../../ressources/styles/theme';
import { transientOptions } from '../Assets/utils';
import StyledToggleButton from './StyledToggleButton';

interface Props {
  items?: {
    value: string,
    icon?: React.ReactNode
  }[];
  alignment: string;
  setAlignment?: React.Dispatch<React.SetStateAction<string>>
}

function ToggleButtonSelect({ items, alignment, setAlignment }: Props) {
  return (
    <ToggleButtonGroup
      size="small"
      value={alignment}
      exclusive
      aria-label="Search by"
      color="primary"
    >
      {
        items && items.map((item) => (
          <StyledToggleButton
            key={item.value}
            selected={false}
            $isselected={alignment === item.value}
            value={item.value}
            onClick={() => setAlignment && setAlignment(item.value)}
          >
            <HBox>
              {item.icon}
              {item.value}
            </HBox>
          </StyledToggleButton>

        ))
      }
    </ToggleButtonGroup>
  );
}

export default ToggleButtonSelect;
