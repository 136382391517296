import React, { useEffect, useState } from 'react';
import {
  Checkbox, Theme, ToggleButton, Tooltip,
} from '@mui/material';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../Assets/utils';
import ListItem from '../../ListItem/ListItem';
import { ListObjectType } from '../../../../types/profiles';
import config from '../../../../config/config';
import StyledToggleButton from '../../../Buttons/StyledToggleButton';

interface Props {
  item: ListObjectType;
  theme: Theme;
  path: string;
  loading: boolean;
  enableSelectAll?: boolean;
  updateAction: (item: {key: string, value: boolean}, path: string) => void;
  handleEnableAll: (path: string, value: boolean) => void;
}

function RecursiveProfileContainer({
  item, theme, path, loading, enableSelectAll, updateAction, handleEnableAll,
}: Props) {
  const [checkedSelectAll, setCheckedSelectAll] = useState(false);
  const [displaySelectAll, setDisplaySelectAll] = useState<boolean>(false);
  const itemValuesAreBoolean = Object.values(item.value).every((value) => typeof value === 'boolean');
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    setCheckedSelectAll(checked);
    handleEnableAll(value, checked);
  };

  useEffect(() => {
    if (enableSelectAll && itemValuesAreBoolean) setDisplaySelectAll(enableSelectAll);
  }, [item]);

  return (
    typeof item.value === 'boolean'
      ? (
        <StyledToggleButton
          disabled={loading}
          value={item.value}
          selected={false}
          size="small"
          color="primary"
          onChange={() => checkedSelectAll && setCheckedSelectAll(false)}
          onClick={() => updateAction({ ...item, value: !item.value }, path)}
          $isselected={item.value}
          sx={{ ml: '2rem', width: 'fit-content' }}
        >
          {capitalizeFirstLetter(item.key)}
        </StyledToggleButton>
      )
      : (
        <ListItem
          key={item.key}
          title={capitalizeFirstLetter(item.key)}
          expandable
          disableSeparator
          rightContent={displaySelectAll && (
            <Tooltip title={checkedSelectAll ? t('general.disableAll') : t('general.enableAll')}>
              <Checkbox
                value={path}
                checked={checkedSelectAll}
                onChange={handleChange}
                icon={<DoneAllOutlinedIcon color="secondary" />}
                checkedIcon={<DoneAllOutlinedIcon color="primary" />}
              />
            </Tooltip>
          )}
        >
          {
            Object.entries(item.value).map(([key, value]) => ({ key, value })).map((subItem, index) => (
              typeof subItem.value === 'boolean' ? (
                <StyledToggleButton
                  key={`${subItem.key}_${index}`}
                  disabled={loading}
                  value={subItem.value}
                  selected={false}
                  size="small"
                  color="primary"
                  onChange={() => checkedSelectAll && setCheckedSelectAll(false)}
                  $isselected={subItem.value}
                  onClick={() => updateAction({ ...item, value: !subItem.value }, `${path}.${subItem.key}`)}
                  sx={{ ml: '1rem', mb: '.5rem', width: 'fit-content' }}
                >
                  {capitalizeFirstLetter(subItem.key)}
                </StyledToggleButton>
              ) : (
                RecursiveProfileContainer({
                  item: subItem, theme, updateAction, path: `${path}.${subItem.key}`, loading, enableSelectAll, handleEnableAll,
                })
              )
            ))
          }
        </ListItem>
      )
  );
}

export default RecursiveProfileContainer;
