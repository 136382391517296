import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { KeyboardArrowDown } from '@mui/icons-material';

interface Props {
    isExpanded: boolean;
}

function ExpandArrow({ isExpanded }: Props) {
  const toggleWrapperAnimatedStyle = useSpring({ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' });

  return (
    <animated.div style={toggleWrapperAnimatedStyle}>
      <KeyboardArrowDown />
    </animated.div>
  );
}

export default ExpandArrow;
