import WatchIcon from '@mui/icons-material/Watch';
import PersonIcon from '@mui/icons-material/Person';
import {
  REDIRECT_URI, API_BASE_URL,
} from './env';

export default {
  api: {
    url: API_BASE_URL,
  },
  oidc: {
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    tokenManager: {
      autoRenew: true,
    },
  },
  general: {
    enterKeyValue: 'Enter',
  },
  adminPage: {
    getAllProfilesValue: 'All profiles',
    internalUserMail: '@louisvuitton.com',
    partnerUserMail: '@lvm-ext.partners.ms.lvmh',
  },
  watchManager: {
    inputNames: {
      serialNumber: 'serialNumber',
      unpairWatchContactis: 'unpairWatchContactis',
      unpairWatchCaseSerial: 'unpairWatchCaseSerial',
      unpairClientName: 'unpairClientName',
      unpairReason: 'unpairReason',
      oldClientName: 'oldClientName',
      oldClientEmail: 'oldClientEmail',
      oldClientId: 'oldClientId',
    },
    values: {
      watchReady: 'Ready',
      unpairHistoryTableHeader: ['Date (UTC Time)', 'Serial Number', 'Login', 'Unpair Reason', 'More'],
      search: {
        user: {
          value: 'Search user',
          icon: <PersonIcon />,
          placeholder: 'Firstname, lastname or email',
        },
        watch: {
          value: 'Search watch',
          icon: <WatchIcon />,
          placeholder: 'Serial number or watch case (Ex: 17E458945231 or UL5368)',
        },
      },
    },
  },
};
