import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ListObjectType, ProfileType,
} from '../../../../types/profiles';
import { HBox, VBox } from '../../../../ressources/styles/styled-generic';
import { ApplicationsType } from '../../../../types/applications';
import { ApiType } from '../../../../types/api';
import { ThemeContext } from '../../../../context/theme/ThemeContext';
import RecursiveProfileContainer from './RecursiveProfileContainer';

interface Props {
  profile: ProfileType;
  setProfile?: React.Dispatch<React.SetStateAction<ProfileType>>;
  loading: boolean;
  enableSelectAll?: boolean;
  updateProfile: (profile: ProfileType) => void;
}

function ExpandedProfile({
  profile, setProfile, loading, enableSelectAll, updateProfile,
}: Props) {
  const [applications, setApplications] = useState<ListObjectType[]>([]);
  const [apis, setApis] = useState<ListObjectType[]>([]);
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  const listObject = (object: ApplicationsType | ApiType | ListObjectType) => Object.entries(object).map(([key, value]) => ({ key, value }));

  const setProperty = (entryObject: ProfileType, propertyPath: string, value: boolean): ProfileType => {
    const [head, ...rest] = propertyPath.split('.');
    return {
      ...entryObject,
      [head]: rest.length
        ? setProperty((entryObject as any)[head], rest.join('.'), value)
        : value,
    };
  };

  const setAllProperties = (entryObject: ProfileType, propertyPath: string, value: boolean): ProfileType => {
    const [head, ...rest] = propertyPath.split('.');
    if (rest.length === 0) {
      const updatedHead: Record<string, boolean> = Object.keys((entryObject as any)[head]).reduce((acc: {[key: string]: boolean}, key) => {
        acc[key] = value;
        return acc;
      }, {});

      return {
        ...entryObject,
        [head]: updatedHead,
      };
    }
    const updatedHead = setAllProperties((entryObject as any)[head], rest.join('.'), value);
    return {
      ...entryObject,
      [head]: updatedHead,
    };
  };

  const handleUpdate = (item: {key: string, value: boolean}, path: string) => {
    updateProfile(setProperty(profile, path, item.value));
    if (setProfile) setProfile(setProperty(profile, path, item.value));
  };

  const handleEnableAll = (path: string, value: boolean) => {
    if (setProfile) setProfile(setAllProperties(profile, path, value));
  };

  useEffect(() => {
    setApplications(listObject(profile.applications));
    setApis(listObject(profile.api));
  }, [profile]);

  return (
    <HBox alignItems="start" gap="4rem" width="100%" height="100%" overflow="auto">
      <VBox width="40%" gap="1rem">
        <Typography ml="2rem" mb=".5rem" fontWeight="bold">{t('adminPage.applicationsTitle')}</Typography>
        <VBox>
          {
            applications.map((application, index) => (
              <RecursiveProfileContainer
                key={`${application}_${index}`}
                item={application}
                theme={theme}
                updateAction={handleUpdate}
                path={`applications.${application.key}`}
                loading={loading}
                enableSelectAll={enableSelectAll}
                handleEnableAll={handleEnableAll}
              />
            ))
          }
        </VBox>
      </VBox>
      <VBox width="40%" gap="1rem">
        <Typography ml="2rem" fontWeight="bold">{t('adminPage.apiTitle')}</Typography>
        <VBox>
          {
            apis.map((api, index) => (
              <RecursiveProfileContainer
                key={`${api}_${index}`}
                item={api}
                theme={theme}
                updateAction={handleUpdate}
                path={`api.${api.key}`}
                loading={loading}
                enableSelectAll={enableSelectAll}
                handleEnableAll={handleEnableAll}
              />
            ))
          }
        </VBox>
      </VBox>
    </HBox>
  );
}

export default ExpandedProfile;
