import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import WatchManagerPage from '../views/WatchManager/WatchManagerPage';
import SearchPage from '../views/WatchManager/Search/SearchPage';
import HistoryPage from '../views/WatchManager/History/HistoryPage';
import AdminPage from '../views/Admin/AdminPage';
import UserManagerPage from '../views/Admin/UserManager/UserManagerPage';
import ProfileManagerPage from '../views/Admin/ProfileManager/ProfileManagerPage';
import AzureLogsPage from '../views/AzureLogs/AzureLogsPage';
import SanityDashboardPage from '../views/SanityDashboard/SanityDashboardPage';
import { CustomRouteObject } from '../types/customRoute';
import { MuiAzureTheme, MuiBlueTheme, MuiPurpleTheme } from '../ressources/styles/theme';
import watchManagerIcon from '../ressources/icons/watch-manager-icon.png';
import adminIcon from '../ressources/icons/admin.png';
import azureIcon from '../ressources/icons/azure-logo.png';
import dashboardIcon from '../ressources/icons/dashboard-icon.png';
import Main from '../views/Main/Main';
import { UserContext } from '../context/user/UserContext';
import { UserContextType } from '../types/user';

export function watchManagerChildren(userInfo?: UserContextType | null): CustomRouteObject[] {
  return [
    {
      name: 'Search',
      path: 'search',
      element: <SearchPage />,
      authorized: userInfo?.applications?.watchv2.manager,
    },
    {
      name: 'Unpair History',
      path: 'history',
      element: <HistoryPage />,
      authorized: userInfo?.applications?.watchv2.manager,
    },
  ];
}

export function adminChildren(userInfo?: UserContextType | null): CustomRouteObject[] {
  return [
    {
      name: 'User Manager',
      path: 'user',
      element: <UserManagerPage />,
      authorized: userInfo?.applications?.admin,
    },
    {
      name: 'Profile Manager',
      path: 'profile',
      element: <ProfileManagerPage />,
      authorized: userInfo?.applications?.admin,
    },
  ];
}

export function homeChildren(): CustomRouteObject[] {
  const { userInfo } = useContext(UserContext);

  return [
    {
      path: 'watch-manager',
      name: 'Watch Manager',
      theme: MuiBlueTheme,
      icon: watchManagerIcon,
      element: <WatchManagerPage />,
      children: () => watchManagerChildren(userInfo),
      authorized: userInfo?.applications?.watchv2.manager,
    },
    {
      path: 'admin',
      name: 'Admin Page',
      theme: MuiPurpleTheme,
      icon: adminIcon,
      element: <AdminPage />,
      children: () => adminChildren(userInfo),
      authorized: userInfo?.applications?.admin,
    },
    {
      path: 'azure-logs',
      name: 'Azure Logs',
      theme: MuiAzureTheme,
      icon: azureIcon,
      element: <AzureLogsPage />,
      authorized: userInfo?.applications?.watch.azurelogs,

    },
    {
      path: 'sanity-dashboard',
      name: 'Sanity Dashboard',
      theme: MuiPurpleTheme,
      icon: dashboardIcon,
      element: <SanityDashboardPage />,
      authorized: userInfo?.applications?.watchv2.dashboard,
    },
  ];
}

export function loggedRoutes(): CustomRouteObject[] {
  return [
    {
      path: '/',
      name: 'Main',
      element: <Main />,
      children: homeChildren,
      authorized: true,
    },
    {
      path: '/*',
      name: 'all',
      element: <Navigate to="/" />,
      authorized: true,
    },
  ];
}
