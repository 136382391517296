import { Box } from '@mui/material';
import styled from 'styled-components';

export const HBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: .5rem;
`;

export const VBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: .5rem;
`;
