import React, {
  useCallback, useEffect, useState,
} from 'react';
import { Security } from '@okta/okta-react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { ThemeProvider } from 'styled-components';
import routes from './routes';
import { theme } from '../ressources/styles/theme';
import Loader from '../components/Assets/Loader/Loader';
import { oktaConfigType } from '../types/okta';
import { api, instance } from '../api/api';
import config from '../config/config';

const BaseRoutes = () => useRoutes(routes);

function AppRouter() {
  const [configuration, setConfiguration] = useState<oktaConfigType>();
  const [oktaAuth, setOktaAuth] = useState<OktaAuth>();
  const navigate = useNavigate();

  const restoreOriginalUri = useCallback(async (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), {
      replace: true,
    });
  }, []);

  const getConfiguration = async (): Promise<void> => {
    try {
      setConfiguration(await api.init.getConfiguration());
    } catch (e) {
      console.error(`Failed to fetch oktaAuth configuration. Error: ${e}`);
    }
  };

  useEffect(() => {
    getConfiguration();
  }, []);

  useEffect(() => {
    if (oktaAuth) {
      instance.interceptors.request.use(async (request) => {
        if (request.headers) request.headers.authorization = `Bearer ${oktaAuth.getAccessToken()}`;
        return request;
      });
    }
  }, [oktaAuth]);

  useEffect(() => {
    if (configuration?.issuer) setOktaAuth(new OktaAuth({ ...config.oidc, ...configuration }));
  }, [configuration]);

  return oktaAuth ? (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <ThemeProvider theme={theme}>
        <BaseRoutes />
      </ThemeProvider>
    </Security>
  ) : <Loader open />;
}

export default AppRouter;
