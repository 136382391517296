import { createContext } from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import { UserContextType } from '../../types/user';

export type InfoUserType = {
  userInfo: UserContextType | null;
  setUserInfos: (infos: UserContextType| null) => void;
};

export const UserContext = createContext<InfoUserType>({} as InfoUserType);
