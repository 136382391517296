import React, { useEffect, useState } from 'react';
import { Button, InputAdornment, OutlinedInput } from '@mui/material';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import ToggleButtonSelect from '../Buttons/ToggleButtonSelect';
import { HBox, VBox } from '../../ressources/styles/styled-generic';
import config from '../../config/config';

interface Props {
    action?: (value?: string, alignment?: string) => void;
    liveSearchAction?: (value: string) => void;
    setAlignment?: React.Dispatch<React.SetStateAction<string>>;
    clearAction?: () => void;
    actionLabel?: string;
    clearLabel?: string;
    actionIcon?: React.ReactNode;
    placeholder?: string;
    alignment?: string;
    toggleButtons?: boolean;
    toggleItems?: {
        value: string,
        icon?: React.ReactNode
    }[];
}

export const SearchContainer = styled(VBox)`
  width: 80%;
  gap: 1rem;
`;

export const ToolBar = styled(HBox)`
  justify-content: space-between;
`;

function Search({
  action, liveSearchAction, setAlignment, clearAction, actionLabel, clearLabel, actionIcon, placeholder, alignment, toggleButtons, toggleItems,
}: Props) {
  const [searchValue, setSearchValue] = useState<string>('');
  const { t } = useTranslation();

  const onKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === config.general.enterKeyValue) {
      if (action) action(searchValue, alignment);
      event.preventDefault();
    }
  };

  const handleClear = () => {
    if (clearAction) clearAction();
    setSearchValue('');
  };

  useEffect(() => {
    setSearchValue('');
  }, [alignment]);

  useEffect(() => {
    if (liveSearchAction) liveSearchAction(searchValue);
  }, [searchValue]);

  return (
    <SearchContainer>
      <ToolBar>
        { toggleButtons && <ToggleButtonSelect items={toggleItems} alignment={alignment || ''} setAlignment={setAlignment} /> }
        <HBox ml="auto">
          <Button variant="contained" onClick={() => action && action(searchValue, alignment && alignment)} startIcon={actionIcon}>{actionLabel}</Button>
          {clearAction && <Button variant="outlined" onClick={handleClear}>{clearLabel || t('general.clear')}</Button>}
        </HBox>
      </ToolBar>
      <OutlinedInput
        placeholder={placeholder}
        value={searchValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value)}
        onKeyDown={onKeyPress}
        startAdornment={(
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
    )}
      />
    </SearchContainer>
  );
}

export default Search;
