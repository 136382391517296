import React, { useContext } from 'react';
import {
  Dialog, DialogTitle, DialogContent, Typography, Paper,
} from '@mui/material';
import styled from 'styled-components';
import { HBox } from '../../ressources/styles/styled-generic';
import { ThemeContext } from '../../context/theme/ThemeContext';

interface Props {
  openDialog: boolean;
  title?: string;
  children?: React.ReactNode;
  headerIcon?: React.ReactNode;
}

const StyledPaper = styled(Paper)<{bordercolor: string}>`
  border-left: .75rem solid ${(props) => props.bordercolor};
  width: fit-content;
  overflow-y: visible;
`;

function DialogWrapper({
  openDialog, title, children, headerIcon,
}: Props) {
  const { theme } = useContext(ThemeContext);

  return (
    <Dialog open={openDialog} maxWidth={false}>
      <StyledPaper bordercolor={theme.palette.primary.main}>
        <DialogTitle>
          <HBox>
            {headerIcon}
            <Typography variant="h6" marginLeft="1rem" color="secondary">{title}</Typography>
          </HBox>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
      </StyledPaper>
    </Dialog>
  );
}

export default DialogWrapper;
