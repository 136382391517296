import { createTheme } from '@mui/material';

export const theme = {
  colors: {
    black: '#000',
    white: '#fff',
    grey: '#808080',
    lightGrey: '#EDEDED',
    veryLightGrey: '#F9F9F8',
    LVBlue: '#0874be',
    LVPurple: '#90258F',
    LVAzure: '#0095C7',
    LVGreen: '#0A8024',

  },
  layout: {
    headerHeight: '6rem',
    navWidth: '16rem',
  },
};

const MuiTheme = createTheme({
  spacing: 4,
  typography: {
    allVariants: {
      color: '#444444',
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
          color: theme.colors.white,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          borderRadius: '10px',
          textTransform: 'none',
          justifyContent: 'center',
          minWidth: '80px',
        },
        text: {
          fontWeight: 'bold',
          textTransform: 'none',
          justifyContent: 'center',
          minWidth: '100px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          minWidth: 'unset',
          minHeight: 'unset',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
          minHeight: 'unset',
          width: '100%',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '10px',
          backgroundColor: theme.colors.white,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        stickyHeader: {
          marginTop: '0',
        },
      },
    },
  },
});

export const MuiDefaultTheme = createTheme({
  ...MuiTheme,
  palette: {
    primary: {
      main: theme.colors.LVGreen,
    },
    secondary: {
      main: theme.colors.grey,
    },
    info: {
      main: theme.colors.white,
    },
  },
});

export const MuiBlueTheme = createTheme({
  ...MuiTheme,
  palette: {
    primary: {
      main: theme.colors.LVBlue,
    },
    secondary: {
      main: theme.colors.grey,
    },
    info: {
      main: theme.colors.white,
    },
  },
});

export const MuiPurpleTheme = createTheme({
  ...MuiTheme,
  palette: {
    primary: {
      main: theme.colors.LVPurple,
    },
    secondary: {
      main: theme.colors.grey,
    },
    info: {
      main: theme.colors.white,
    },
  },
});

export const MuiAzureTheme = createTheme({
  ...MuiTheme,
  palette: {
    primary: {
      main: theme.colors.LVAzure,
    },
    secondary: {
      main: theme.colors.grey,
    },
    info: {
      main: theme.colors.white,
    },
  },
});
