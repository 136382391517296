import React from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { UserType } from '../../../../types/user';
import List from '../../../../components/List/List';
import ListItem from '../../../../components/List/ListItem/ListItem';
import ExpandedUser from '../../../../components/List/ExpandedItems/ExpandedUser/ExpandedUser';
import { capitalizeFirstLetter } from '../../../../components/Assets/utils';
import WatchCount from '../../../../components/Assets/WatchCount/WatchCount';

interface Props {
    users: UserType[];
    getUsersWatches: () => Promise<void>;
    autoExpand?: boolean;
}

function UserResults({ users, getUsersWatches, autoExpand }: Props) {
  return (
    <List>
      {
        users.map((user) => (
          <ListItem
            key={user.accountId}
            title={`${user.firstname && capitalizeFirstLetter(user.firstname)} ${user.lastname?.toUpperCase()}`}
            middleText={user.email}
            expandable={user.watches && user.watches.length > 0}
            autoExpand={autoExpand}
            highlight
            icon={
              <PersonOutlineOutlinedIcon fontSize="large" color="secondary" />
            }
            rightContent={
              (user.watches && user.watches.length > 0) && <WatchCount count={user.watches.length} />
            }
          >
            <ExpandedUser user={user} resetAction={getUsersWatches} />
          </ListItem>
        ))
      }
    </List>
  );
}

export default UserResults;
