import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Outlet, useNavigate } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import { HBox } from '../../ressources/styles/styled-generic';
import Menu from '../../components/Menu/Menu';
import Loader from '../../components/Assets/Loader/Loader';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { SnackBarContext } from '../../context/snackBar/SnackBarContext';
import { homeChildren } from '../../routes/LoggedRoutes';
import { CustomRouteObject } from '../../types/customRoute';

const MainContainer = styled(HBox)`
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: hidden;
`;

function Main() {
  const { loading } = useContext(LoadingContext);
  const { snack } = useContext(SnackBarContext);
  const navigate = useNavigate();

  return (
    <MainContainer>
      <Menu />
      <Snackbar open={snack.open} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity={snack.severity} variant="filled" sx={{ width: '100%' }}>
          {snack.message}
        </Alert>
      </Snackbar>
      <Outlet />
      <Loader open={loading} />
    </MainContainer>
  );
}

export default Main;
