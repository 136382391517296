import { Box, styled } from '@mui/material';
import React from 'react';

interface Props {
    children: React.ReactNode;
}

const ExpendedContainer = styled(Box)`
  width: 100%;
  max-height: 30rem;
`;

function ExpandedItem({ children }: Props) {
  return (
    <ExpendedContainer>{children}</ExpendedContainer>
  );
}

export default ExpandedItem;
