import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

interface Props {
    open: boolean;
}

function Loader({ open }: Props) {
  return (
    <Backdrop
      sx={(theme) => ({ color: theme.palette.primary.main, zIndex: theme.zIndex.drawer + 1 })}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Loader;
